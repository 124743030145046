//This is circular dependency issue, so we disabled auto sorting by eslint.
/* eslint-disable simple-import-sort/imports */
import { searchNodes } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { StructureDto, StructureSearchedDto } from '@/endpoints/models'
import { ContextMenu, Loader } from '@/components'
import { Container, TreeContainer, SearchContainer } from './styles'
import { SearchBar } from '@/components/SearchBar/SearchBar'
import { Tree } from '@/components/Tree/Tree'
import {
	collapseNode,
	deselectNodes,
	expandNode,
	loadNodeChildren,
	loadSystemNodes,
	selectAllNode,
	deselectAllNode,
	selectNode,
} from '@/store/modules/node/actions'
import { openTab } from '@/store/modules/tab/actions'
import { nextFrame } from '@/utils/async'
import { isNotUndefined, NativeMap } from '@/utils/collections'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useLocalStore,
} from '@/hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EntityTypeIcon } from '../../components/EntityTypeIcon'
import { EmptySearch } from './components/EmptySearch'
import { Header } from './components/Header'
import { ItemMenu } from './components/ItemMenu'
import { NodeActions } from './components/NodeActions/NodeActions'
import { useDragHandler } from './drag'
import {
	ExtendedTreeNode,
	mapToTree,
	sortNodes,
	TREE_PANEL_ORDER,
} from './utils'
import SimpleBarReact from 'simplebar-react'

const TreePanelComponent = () => {
	const { t } = useAppContext()
	const request = useApiRequest()
	const dispatch = useAppDispatch()
	const nodes = useAppStore((state) => state.node.nodes)
	const roots = useAppStore((state) => state.node.roots)
	const children = useAppStore((state) => state.node.children)
	const expanded = useAppStore((state) => state.node.expanded)
	const selectedId = useAppStore((state) => state.tab.selectedId)
	const selectMode = useAppStore((state) => state.node.selectMode)
	const selectedNodes = useAppStore((state) => state.node.selectedNodes)

	const [contextOpened, setContextOpened] = useState(false)

	const [contextNode, setContextNode] = useState(null as StructureDto | null)

	const [contextX, setContextX] = useState(0)
	const [contextY, setContextY] = useState(0)

	// use alphabetical order - ignore node orderPriority
	const [alphabet, setAlphabet] = useLocalStore(TREE_PANEL_ORDER, true)

	const [searchTree, setSearchTree] = useState(
		null as null | StructureSearchedDto[],
	)

	const [searchQuery, setSearchQuery] = useState(
		undefined as string | undefined,
	)

	const [searchFocus, setSearchFocus] = useState(
		undefined as number | undefined,
	)

	useEffect(() => {
		dispatch(loadSystemNodes())
	}, [dispatch])

	useEffect(() => {
		async function load() {
			if (searchQuery && searchQuery.length > 1) {
				const { data } = await request(searchNodes(searchQuery))
				setSearchTree(data)
			} else {
				setSearchTree(null)
			}
		}

		load()
	}, [searchQuery])

	const handleContextClose = () => {
		setContextOpened(false)
	}

	const treeData = useMemo(() => {
		const data = searchTree
			? (searchTree as unknown as StructureDto[])
			: roots?.map((id) => nodes[id]).filter(isNotUndefined)

		const sorted = data
			.sort((a, b) => sortNodes(a, b, alphabet))
			.map((node) =>
				mapToTree(
					node,
					children,
					nodes,
					searchTree ? searchQuery : undefined,
					alphabet,
				),
			)

		return sorted
	}, [searchTree, roots, nodes, alphabet, children, searchQuery])

	const treeExpanded = useMemo(() => {
		if (searchTree) {
			const result = [] as number[]

			const add = (node: StructureSearchedDto) => {
				if (node.children.length > 0) {
					result.push(node.id)
					node.children.forEach(add)
				}
			}

			searchTree.forEach(add)

			return result
		}

		return expanded
	}, [expanded, searchTree])

	const treePaths = useMemo((): NativeMap<number[]> => {
		if (!searchTree) {
			return {}
		}

		const paths = {} as NativeMap<number[]>

		const add = (path: number[], node: StructureSearchedDto) => {
			paths[node.id] = path

			if (node.children.length > 0) {
				const parentPath = [...path, node.id]
				node.children.forEach((c) => add(parentPath, c))
			}
		}

		searchTree.forEach((c) => add([], c))

		return paths
	}, [searchTree])

	const cancelSearch = useCallback(
		async (node?: StructureDto) => {
			setSearchQuery(undefined)
			setSearchTree(null)

			if (node) {
				// Wait for the tree to rerender
				await nextFrame()

				// Expand all parent nodes
				const path = treePaths[node.id]

				if (path) {
					await Promise.all(path.map((id) => dispatch(expandNode(id))))
				}

				// Scroll to node if needed
				setSearchFocus(node.id)
			}
		},
		[setSearchQuery, dispatch, treePaths],
	)

	const handleDisabledSorting = useCallback(() => {
		setAlphabet(false)
	}, [setAlphabet])

	const dragDropHandler = useDragHandler({
		onSortingDisabled: handleDisabledSorting,
	})

	const handleNodeSelect = useCallback(
		(isChecked: boolean, item: ExtendedTreeNode, isSingle: boolean) => {
			if (isChecked) {
				dispatch(isSingle ? selectNode(item.node) : selectAllNode(item.node))
			} else {
				dispatch(
					isSingle ? deselectNodes([item.node.id]) : deselectAllNode(item.node),
				)
			}
		},
		[dispatch],
	)

	const isCheckedFun = useCallback(
		(item: ExtendedTreeNode) => selectedNodes.includes(item.node.id),
		[selectedNodes],
	)

	const iconComponent = (node: ExtendedTreeNode, expanded: boolean) => (
		<EntityTypeIcon type={node.node.type} expanded={expanded} />
	)

	const actionComponent = (node: ExtendedTreeNode) => (
		<NodeActions node={node} />
	)

	return (
		<Container role="complementary">
			<Header alphabet={alphabet} setAlphabet={setAlphabet} />

			<SearchContainer>
				<SearchBar
					debounceTime={200}
					clearable={true}
					placeholder={t('SEARCH_PLACEHOLDER')}
					value={searchQuery as string}
					isCompact={true}
					setValue={setSearchQuery}
					name="TreePanelSearch"
				/>
			</SearchContainer>

			<TreeContainer>
				<Loader loaded={!!treeData} />
				<SimpleBarReact>
					{treeData && (
						<Tree<ExtendedTreeNode>
							roots={treeData}
							expanded={treeExpanded}
							highlighted={selectedId || null}
							focused={searchFocus}
							onFocused={() => setSearchFocus(undefined)}
							getDragDrop={dragDropHandler}
							onItemClick={(node) => {
								if (searchTree) {
									cancelSearch(node.node)
								}

								dispatch(openTab(node.node, true))
							}}
							onItemDblClick={(node) => {
								if (searchTree) {
									cancelSearch(node.node)
								}

								dispatch(openTab(node.node, false))
							}}
							onItemContextMenu={(node, event) => {
								event.preventDefault()
								event.stopPropagation()

								setContextOpened(true)
								setContextNode(node.node)
								setContextX(event.pageX)
								setContextY(event.pageY)
							}}
							onItemExpand={(node, expanded) => {
								if (searchTree) {
									cancelSearch(node.node)
								} else {
									const key = node.key as number

									if (expanded) {
										dispatch(expandNode(key))
										dispatch(loadNodeChildren(key))
									} else {
										dispatch(collapseNode(key))
									}
								}
							}}
							iconComponent={iconComponent}
							actionComponent={actionComponent}
							onItemCheckChange={handleNodeSelect}
							showCheckboxes={selectMode}
							isCheckedFunc={isCheckedFun}
						/>
					)}
					{searchTree !== undefined && searchTree?.length === 0 && (
						<EmptySearch />
					)}
				</SimpleBarReact>
			</TreeContainer>

			<ContextMenu
				opened={contextOpened}
				x={contextX}
				y={contextY}
				onClose={handleContextClose}
			>
				{contextNode && <ItemMenu node={contextNode} />}
			</ContextMenu>
		</Container>
	)
}

export const TreePanel = React.memo(TreePanelComponent)
