export default {
	grid: {
		horizontalLineColor: '#D3D3D3',
		verticalLineColor: '#D3D3D3',
		intervalHorizontalLineColor: '#808080',
		intervalVerticalLineColor: '#808080',
	},
	node: {
		background: '#ffffff',
		border: '#3d3d3d',
		hasChanged: '#ff0000',
		header: '#4195a8',
		bodyBackground: '#FFFFED',
		body: '#FCFAF6',
		lightBorder: '#a9a9a9',
	},
	link: {
		default: '#add8e6',
	},
}
