import { ChangeEvent, useMemo } from 'react'

import { useAppContext, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { RootState } from '@/store'

import {
	updateSpecificNodeBodyColor,
	updateSpecificNodeHeaderColor,
} from '../handlers/nodeHandlers'
import { NodePropertiesProps } from '../types'
import { getNodePath } from '../utils'
import {
	DiagramPropertiesContent,
	PropertyType,
} from './DiagramPropertiesContent'

export const NodeProperties = ({
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
	nodeId,
}: NodePropertiesProps) => {
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const { t } = useAppContext()

	const selectedNode = useAppSelector(
		(state) =>
			state.diagram.diagrams[nodeId]?.form.graph.inspector.inspectedData,
	)

	const thisNode = useAppSelector((state) =>
		state.diagram.diagrams[nodeId].form.graph.nodeDataArray.find(
			(node) => node.key === selectedNode?.key,
		),
	)

	const getAllNodesFromTree = useAppSelector(
		(state: RootState) => state?.node?.nodes,
	)

	const onChange = (e: ChangeEvent<HTMLInputElement>, inputType: string) => {
		switch (inputType) {
			case 'SPECIFIC_NODE_HEADER_COLOR':
				return updateSpecificNodeHeaderColor(
					diagramRef,
					selectedNode?.key,
					e.target.value,
				)

			case 'SPECIFIC_NODE_BODY_COLOR':
				return updateSpecificNodeBodyColor(
					diagramRef,
					selectedNode?.key,
					e.target.value,
				)

			default:
				break
		}
	}

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: selectedNode?.key,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_NAME'),
				contentValue: selectedNode?.text,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: selectedNode?.category,
				type: PropertyType.Info,
				isGrouped: true,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_FULL_PATH'),
				contentValue:
					getNodePath(getAllNodesFromTree, selectedNode?.key) ||
					t('DIAGRAM_NODE_DELETED'),
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				contentValue: (
					<input
						type="color"
						value={thisNode?.nodeHeaderColor}
						onChange={(e) => onChange(e, 'SPECIFIC_NODE_HEADER_COLOR')}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_BODY_COLOR'),
				contentValue: (
					<input
						type="color"
						value={thisNode?.nodeBodyColor}
						onChange={(e) => onChange(e, 'SPECIFIC_NODE_BODY_COLOR')}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Table,
			},
		],
		[thisNode, t, selectedNode, getAllNodesFromTree, isEditMode],
	)

	handlePropertiesTitle(t('DIAGRAM_NODE_PROPERTIES'))

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
