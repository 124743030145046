import Button from '@mui/material/Button'
import { grey } from '@mui/material/colors'
import {
	createContext,
	ReactNode,
	useCallback,
	useContext,
	useRef,
	useState,
} from 'react'

import { LinearProgressWithLabel } from '@/components/LinearProgressWithLabel'
import { useAppContext } from '@/hooks'

import { ConfirmationOptions } from '../types'
import { ConfirmationDialog } from './ConfirmationDialog'

const ConfirmationServiceContext = createContext<
	(options: ConfirmationOptions) => Promise<ConfirmationOptions | null>
>(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({
	children,
}: {
	children: ReactNode
}) => {
	const { t } = useAppContext()

	const [confirmationState, setConfirmationState] =
		useState<ConfirmationOptions | null>(null)
	const [isProcessing, setIsProcessing] = useState(false)
	const isProcessingStarted = isProcessing && confirmationState?.progress
	const awaitingPromiseRef = useRef<{
		reject: () => void
		resolve: (options: ConfirmationOptions | null) => void
	}>()

	const openConfirmation = useCallback((options: ConfirmationOptions) => {
		setConfirmationState(options)

		return new Promise<ConfirmationOptions | null>((resolve, reject) => {
			awaitingPromiseRef.current = { resolve, reject }
		})
	}, [])

	const handleClose = () => {
		if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
			awaitingPromiseRef.current.reject()
		}

		setConfirmationState(null)
		setIsProcessing(false)
	}

	const handleSubmit = (isCanceled?: boolean) => {
		if (awaitingPromiseRef.current) {
			const options = confirmationState
				? { ...confirmationState, isCanceled }
				: null

			awaitingPromiseRef.current.resolve(options)
		}

		if (!isCanceled && confirmationState?.isAsync) {
			setIsProcessing(true)
		} else {
			setConfirmationState(null)
			setIsProcessing(false)
		}
	}

	const footer = () => {
		return (
			<>
				{isProcessingStarted ? (
					<>
						<Button
							variant="contained"
							color="success"
							onClick={handleClose}
							disabled={confirmationState?.progress < 100}
						>
							{t('FINISH')}
						</Button>
					</>
				) : (
					<>
						<Button
							variant="outlined"
							sx={{
								color: grey[400],
								borderColor: grey[400],
							}}
							onClick={() => handleSubmit(true)}
						>
							{t('CANCEL')}
						</Button>
						<Button variant="outlined" color="success" onClick={handleClose}>
							{t('SAVE_ONLY')}
						</Button>
						<Button
							variant="contained"
							color="success"
							onClick={() => handleSubmit()}
						>
							{t('SAVE_AND_UPDATE')}
						</Button>
					</>
				)}
			</>
		)
	}

	const getDescription = () => {
		if (isProcessingStarted) {
			return (
				<div className="w-full">
					{confirmationState?.description}
					<LinearProgressWithLabel value={confirmationState?.progress} />
				</div>
			)
		}
		return confirmationState?.description
	}

	return (
		<>
			<ConfirmationServiceContext.Provider value={openConfirmation}>
				{children}
			</ConfirmationServiceContext.Provider>

			<ConfirmationDialog
				open={Boolean(confirmationState)}
				onSubmit={handleSubmit}
				onClose={handleClose}
				title={confirmationState?.title || ''}
				description={getDescription()}
				footer={footer}
			/>
		</>
	)
}
