import { Diagram } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { debounce } from 'lodash'
import { RefObject } from 'react'
import { Dispatch } from 'redux'

import { getModelAsObject } from '@/components/Diagram/utils'
import { StructureDto, TableDiagramDto } from '@/endpoints/models'
import { linksParsed, nodeInserted, skipSet } from '@/store'
import { saveDiagram } from '@/store/slices/thunks'
import { NodeData } from '@/types'

import { updateDiagramProperty } from './utils'

export const updateSpecificNodeHeaderColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	nodeKey: number | undefined,
	updatedColor: string,
) => {
	updateDiagramProperty(diagramRef, 'update node color', (model) => {
		const nodeData = model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			return
		}

		return model.setDataProperty(nodeData, 'nodeHeaderColor', updatedColor)
	})
}

export const updateSpecificNodeBodyColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	nodeKey: number | undefined,
	updatedColor: string,
) => {
	updateDiagramProperty(diagramRef, 'update node color', (model) => {
		const nodeData = model.findNodeDataForKey(nodeKey)

		if (!nodeData) {
			return
		}

		return model.setDataProperty(nodeData, 'nodeBodyColor', updatedColor)
	})
}

export const addDiagramNode = (
	dispatch: Dispatch<any>,
	payload: NodeData,
	deepDiagram: TableDiagramDto | undefined,
	diagramRef: RefObject<ReactDiagram> | undefined,
	node?: StructureDto | null,
) => {
	const instanceDiagram = diagramRef?.current?.getDiagram()
	const parsedModel = getModelAsObject(instanceDiagram as Diagram)
	const saveDebounced = debounce(
		() => dispatch(saveDiagram(node as StructureDto)),
		1000,
	)

	const isNodeDuplicate = parsedModel?.nodeDataArray.find(
		(node: NodeData) => node.key === payload.key,
	)

	if (isNodeDuplicate) return

	dispatch(nodeInserted({ nodeId: node?.id as number, data: payload }))
	dispatch(linksParsed({ nodeId: node?.id as number, data: deepDiagram }))

	saveDebounced()
	dispatch(skipSet({ nodeId: node?.id as number, data: false }))
}
