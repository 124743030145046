export const DEV_URL = 'https://adastra.metacroc-dev.com'
export const CEZ_STAGE_URL = 'https://cez.metacroc-stage.com'
export const DRMAX_STAGE_URL = 'https://drmax.metacroc-stage.com'
export const PRIVATBANK_STAGE_URL = 'https://privatbank.metacroc-stage.com'
export const LOCALHOST = 'http://localhost'
export const DEV_AUTH_URL = 'https://adastra.metacroc-dev.com/auth'
export const CEZ_STAGE_AUTH_URL = 'https://cez.metacroc-stage.com/auth'
export const DRMAX_STAGE_AUTH_URL = 'https://drmax.metacroc-stage.com/auth'
export const PRIVATBANK_STAGE_AUTH_URL =
	'https://privatbank.metacroc-stage.com/auth'
