import styled from 'styled-components'
export const Container = styled.div`
	background-color: ${(props) =>
		props.theme.colors.layout.treePanel.backgroundColor};
	flex-grow: 1;
	overflow: auto;
	display: flex;
	flex-direction: column;
`

export const TreeContainer = styled.div`
	color: #333;
	flex-grow: 1;
	overflow: auto;
	.simplebar-placeholder {
		display: none;
	}
`

export const SearchContainer = styled.div`
	display: flex;
	border-bottom: 1px solid #ddd;

	input[type='text'] {
		border: 0;
		padding-left: 10px;
	}
`
