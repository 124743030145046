import go from 'gojs'
import { useDispatch } from 'react-redux'

import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { addDiagramNode } from '@/components/Diagram/handlers/nodeHandlers'
import { DiagramTemplateType } from '@/components/Diagram/Templates/enums'
import { getNodeData } from '@/components/Diagram/Templates/NodeTemplates/api'
import { StructureDto } from '@/endpoints/models'
import { useAppSelector } from '@/hooks'
import { TREE_PANEL_DRAG_DROP_NODE_ID } from '@/pages/User/pages/Home/components/TreePanel/drag'
import { useGetDeepDiagramQuery } from '@/rtkQueries'
import diagramColors from '@/styles/diagramColors'

export const useReactDiagramDrop = () => {
	const dispatch = useDispatch()
	const { diagramRef, node } = useDiagramContext()
	const allTreeNodes = useAppSelector((state) => state?.node?.nodes)
	const { token } = useAppSelector((state) => state.auth)
	const { data: deepDiagram } = useGetDeepDiagramQuery(
		node?.parentStructureId as number,
	)

	const handleDrop = async (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()

		const droppedNodeId = Number(
			e.dataTransfer.getData(TREE_PANEL_DRAG_DROP_NODE_ID),
		)

		const nodeData = await getNodeData(droppedNodeId, token)
		const parsedNodeData = JSON.parse(nodeData?.data)
		const columns = parsedNodeData?.columns || []

		const droppedNode = allTreeNodes[droppedNodeId] as StructureDto

		const instanceDiagram = diagramRef?.current?.getDiagram()
		const model = instanceDiagram?.model as go.GraphLinksModel

		if (!model || !instanceDiagram || !instanceDiagram.div) return

		const diagramRect = instanceDiagram.div.getBoundingClientRect()
		const offsetX = e.clientX - diagramRect.left
		const offsetY = e.clientY - diagramRect.top

		// Convert the client coordinates to diagram coordinates
		const docPoint = instanceDiagram.transformViewToDoc(
			new go.Point(offsetX, offsetY),
		)

		const loc = `${docPoint?.x} ${docPoint?.y}`

		const nodePayload = {
			key: droppedNode?.id,
			category: DiagramTemplateType.Table,
			text: droppedNode.name,
			code: droppedNode.code,
			loc,
			columns,
			tableColumnsVisibility: true,
			nodeBodyColor: diagramColors.node.body,
			nodeHeaderColor: diagramColors.node.header,
		}

		addDiagramNode(dispatch, nodePayload, deepDiagram, diagramRef, node)
	}

	const handleDragOver = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	const handleDragLeave = (e: React.DragEvent) => {
		e.preventDefault()
		e.stopPropagation()
	}

	return {
		handleDrop,
		handleDragOver,
		handleDragLeave,
	}
}
