import Box from '@mui/material/Box'
import LinearProgress, {
	LinearProgressProps,
} from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'

import { mainColors } from '@/styles'

export function LinearProgressWithLabel(
	props: LinearProgressProps & { value: number },
) {
	return (
		<Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
			<Box sx={{ width: '100%', mr: 1 }}>
				<LinearProgress
					sx={{
						'& .MuiLinearProgress-bar': {
							backgroundColor: mainColors.primary,
						},
						backgroundColor: 'rgba(38, 85, 96, 0.1)',
					}}
					variant="determinate"
					{...props}
				/>
			</Box>
			<Box sx={{ minWidth: 35 }}>
				<Typography
					variant="body2"
					sx={{ color: 'text.secondary' }}
				>{`${Math.round(props.value)}%`}</Typography>
			</Box>
		</Box>
	)
}
