import { LsKey } from '@/enums'

export const getDeletedNodes = (): number[] => {
	const storedDeletedNodes = localStorage.getItem(LsKey.DeletedNodes)
	return storedDeletedNodes ? JSON.parse(storedDeletedNodes) : []
}

export const addDeletedNode = (deletedNodeId: number): void => {
	const deletedNodes = getDeletedNodes()

	// Create a Set for uniqueness
	const deletedNodesSet = new Set(deletedNodes)
	deletedNodesSet.add(deletedNodeId)

	// Update localStorage with the new set of unique node IDs
	localStorage.setItem(LsKey.DeletedNodes, JSON.stringify([...deletedNodesSet]))
}

export const clearDeletedNodes = (): void => {
	localStorage.removeItem(LsKey.DeletedNodes)
}
