import { createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { ObjectData } from 'gojs'

import { StructureDto, TableDiagramDto } from '@/endpoints/models'
import { DiagramTab } from '@/pages/User/pages/Home/pages/DiagramDetail/components/enums'
import { StructureDtoRedux } from '@/store/modules/node'
import {
	DiagramState,
	InspectedType,
	OpenedDiagramData,
} from '@/store/slices/diagram/types'
import { getInitialDiagramData } from '@/store/slices/diagram/utils'
import { initDiagram, saveDiagram } from '@/store/slices/thunks'
import { updateTabData } from '@/store/utils'
import { LinkData, NodeData } from '@/types'
import { getDeletedNodes } from '@/utils'

const initialState: DiagramState = {
	asyncStatus: {
		isInitLoading: false,
		initError: '',
		isSaveLoading: false,
		saveError: '',
	},
	diagrams: {} as OpenedDiagramData,
	isAddModalOpen: false,
	isDeleteModalOpen: false,
	nodeToDelete: undefined,
	nodeIdToRemove: 0,
}

function removeItem(array: any[], payload: number) {
	return array?.filter((item) => item.key !== payload)
}

const diagramSlice = createSlice({
	name: 'diagram',
	initialState,
	reducers: {
		linksParsed(
			state,
			action: PayloadAction<{
				data: TableDiagramDto | undefined
				nodeId: number
			}>,
		) {
			const { nodeId, data } = action.payload

			const deepDiagram = data
			const thisDiagram = state.diagrams?.[nodeId]?.form.graph

			if (thisDiagram) {
				const diagramFormLinks = thisDiagram.linkDataArray

				thisDiagram.linkDataArray =
					deepDiagram?.links?.map((deepLink, index: number) => {
						const matchedDiagramFormLink = diagramFormLinks?.find(
							(diagramFormLink) =>
								diagramFormLink?.text === deepLink.constraintName,
						)
						return {
							key: index,
							from: deepLink.foreignKeyTableId,
							to: deepLink.primaryKeyTableId,
							text: deepLink.constraintCode,
							linksColor: matchedDiagramFormLink?.linksColor,
							hasName: matchedDiagramFormLink?.hasName,
						}
					}) || []
			}
		},
		linkModified: (
			state,
			action: PayloadAction<{
				data: go.ObjectData
				linkIndex: number
				nodeId: number
			}>,
		) => {
			const { nodeId, data, linkIndex } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.linkDataArray[linkIndex] = data
			}
		},
		linksFiltered: (
			state,
			action: PayloadAction<{
				nodeId: number
				removedNodeKey: number
			}>,
		) => {
			const { nodeId, removedNodeKey } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.linkDataArray =
					diagram.form.graph.linkDataArray.filter(
						(link) => link.to !== removedNodeKey,
					)
			}
		},
		setNodeIdToRemove: (state, action: PayloadAction<number>) => {
			state.nodeIdToRemove = action.payload
		},
		setNodeToDelete: (state, action: PayloadAction<StructureDtoRedux>) => {
			state.nodeToDelete = action.payload
		},
		openOrCloseDeleteModal: (state, action: PayloadAction<boolean>) => {
			state.isDeleteModalOpen = action.payload
		},
		openOrCloseAddModal: (state, action: PayloadAction<boolean>) => {
			state.isAddModalOpen = action.payload
		},
		diagramUpdated: (
			state,
			action: PayloadAction<{ node: any; update: any }>,
		) => {
			const { node, update } = action.payload

			state.diagrams = updateTabData(state.diagrams, node.id, {
				form: update,
				dirty: true,
			})
		},
		nodeInserted: (
			state,
			action: PayloadAction<{ data: any; nodeId: number }>,
		) => {
			const { nodeId, data } = action.payload

			const diagram = state.diagrams[nodeId]
			if (nodeId) {
				diagram.form.graph.nodeDataArray.push(data)
			}
		},
		nodeModified: (
			state,
			action: PayloadAction<{
				data: ObjectData
				nodeId: number
				nodeIndex: number
			}>,
		) => {
			const { nodeId, data, nodeIndex } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.nodeDataArray[nodeIndex] = data
			}
		},
		diagramPropUpdated: (
			state,
			action: PayloadAction<{ data: any; nodeId: number }>,
		) => {
			const { nodeId, data } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.properties = data
			}
		},
		nodeRemoved: (
			state,
			action: PayloadAction<{ nodeDiagramId: number; removedNodeId: number }>,
		) => {
			const { nodeDiagramId, removedNodeId } = action.payload

			const diagram = state.diagrams[nodeDiagramId]
			console.log('current diagram state: ', current(diagram))
			if (diagram) {
				diagram.form.graph.nodeDataArray = removeItem(
					diagram.form.graph.nodeDataArray,
					removedNodeId,
				)
			}
		},
		skipSet: (
			state,
			action: PayloadAction<{ data: boolean; nodeId: number }>,
		) => {
			const { nodeId, data } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.skipsDiagramUpdate = data
			}
		},
		changeInspected: (
			state,
			action: PayloadAction<{
				data: LinkData | Partial<NodeData> | null
				nodeId: number
				type: InspectedType
			}>,
		) => {
			const { data, nodeId, type } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram) {
				diagram.form.graph.inspector = {
					inspectedData: data,
					type,
				}
			}
		},
		editInspected: (
			state,
			action: PayloadAction<{ data: boolean; nodeId: number }>,
		) => {
			return {
				...state,
				selectedData: action.payload,
			}
		},
		gridVisibilitySet: (
			state,
			action: PayloadAction<{ data: boolean; nodeId: number }>,
		) => {
			const { nodeId, data } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram.form.graph.properties) {
				diagram.form.graph.properties.grid.isVisible = data
			}
		},
		constraintNameSet: (
			state,
			action: PayloadAction<{ data: boolean; nodeId: number }>,
		) => {
			const { nodeId, data } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram.form.graph.properties) {
				diagram.form.graph.properties.displayOptions.tableConstraintName = data
			}
		},
		gridSizeSet: (
			state,
			action: PayloadAction<{ data: number; nodeId: number }>,
		) => {
			const { nodeId, data } = action.payload

			const diagram = state.diagrams[nodeId]
			if (diagram.form.graph.properties) {
				diagram.form.graph.properties.grid.cellSize = data
			}
		},
	},
	extraReducers: (builder) => {
		builder
			// initDiagram cases
			.addCase(initDiagram.pending, (state) => {
				state.asyncStatus.isInitLoading = true
				state.asyncStatus.initError = ''
			})
			.addCase(
				initDiagram.fulfilled,
				(
					state,
					action: PayloadAction<{
						editMode: boolean
						force: boolean
						node: any
					}>,
				) => {
					const { node, editMode } = action.payload
					const previous = state.diagrams[node.id]

					if (previous && editMode && previous.parsedEditMode) {
						return
					}

					const serializedData = editMode
						? node.workingData || node.data
						: node.data
					const parsed = JSON.parse(serializedData as string)
					const original = JSON.parse(node.data as string)

					// Restore nodeDataArray and apply deletion markers
					const updatedNodeDataArray = parsed?.graph?.nodeDataArray?.map(
						(node: NodeData) => {
							const deletedNode = getDeletedNodes().find(
								(deleteNodeId) => deleteNodeId === node.key,
							)
							return deletedNode ? { ...node, isDeleted: true } : node
						},
					)

					state.diagrams[node.id] = {
						form: {
							...getInitialDiagramData(node.name),
							...parsed,
							graph: {
								...getInitialDiagramData(node.name).graph,
								...parsed.graph,
								nodeDataArray: updatedNodeDataArray || [],
							},
						},
						tab: previous ? previous.tab : DiagramTab.Overview,
						dirty: false,
						parsedEditMode: editMode,
						original,
					}
					state.asyncStatus.isInitLoading = false
				},
			)
			.addCase(initDiagram.rejected, (state, action) => {
				state.asyncStatus.isInitLoading = false
				state.asyncStatus.initError =
					action.error.message || 'Failed to initialize diagram'
			})

			// saveDiagram cases
			.addCase(saveDiagram.pending, (state) => {
				state.asyncStatus.isSaveLoading = true
				state.asyncStatus.saveError = ''
			})
			.addCase(
				saveDiagram.fulfilled,
				(state, action: PayloadAction<StructureDto>) => {
					const { id } = action.payload
					if (state.diagrams[id]) {
						state.diagrams[id] = {
							...state.diagrams[id],
							dirty: false,
						}
					}
					state.asyncStatus.isSaveLoading = false
				},
			)
			.addCase(saveDiagram.rejected, (state, action) => {
				state.asyncStatus.isSaveLoading = false
				state.asyncStatus.saveError =
					action.error.message || 'Failed to save diagram'
			})
	},
})

export const {
	diagramUpdated,
	nodeInserted,
	nodeModified,
	setNodeToDelete,
	diagramPropUpdated,
	nodeRemoved,
	openOrCloseDeleteModal,
	skipSet,
	setNodeIdToRemove,
	openOrCloseAddModal,
	changeInspected,
	linksParsed,
	gridVisibilitySet,
	gridSizeSet,
	constraintNameSet,
	linkModified,
	linksFiltered,
} = diagramSlice.actions

export default diagramSlice.reducer
