import { ThemeProvider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import { getSessionContext } from '@/endpoints'
import { useApiRequest } from '@/endpoints/hooks'
import { useAppDispatch } from '@/hooks'
import { setToken, setUser } from '@/store/modules/auth/actions'
import { GlobalStyle } from '@/styles/global'
import theme from '@/styles/theme'

import { AppRouter } from './AppRouter'
import { ApiErrorMessage } from './components/ApiErrorMessage'
import { ConfirmationServiceProvider } from './components/ConfirmationService'
import { ErrorContainer } from './components/ErrorContainer'
import { AppContainer } from './styles'
import { AppProps } from './types'

export const App = ({ token }: AppProps) => {
	const dispatch = useAppDispatch()
	const [isSessionContextLoaded, setIsSessionContextLoaded] = useState(false)
	const request = useApiRequest()

	useEffect(() => {
		const updateToken = async () => {
			if (token) {
				dispatch(setToken(token))

				if (!isSessionContextLoaded) {
					await request(getSessionContext()).then((value) => {
						value.data?.user && dispatch(setUser(value.data.user))
						setIsSessionContextLoaded(true)
					})
				}
			}
		}

		updateToken()
	}, [dispatch, isSessionContextLoaded, request, token])

	return (
		<ThemeProvider theme={theme}>
			<AppContainer>
				<GlobalStyle />
				<ErrorContainer>
					<ConfirmationServiceProvider>
						<DndProvider backend={HTML5Backend}>
							<ApiErrorMessage />
							{isSessionContextLoaded && <AppRouter />}
						</DndProvider>
					</ConfirmationServiceProvider>
				</ErrorContainer>
			</AppContainer>
		</ThemeProvider>
	)
}
