import go, { Routing } from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'
import { Dispatch } from 'redux'

import { updateDiagramProperty } from '@/components/Diagram/handlers/utils'
import { StructureDto } from '@/endpoints/models'
import { constraintNameSet, gridSizeSet, gridVisibilitySet } from '@/store'
import { saveDiagram } from '@/store/slices/thunks'

type DiagramHandler = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	properties: Record<string, any>,
) => void

// Function to update node properties
const updateNodes: DiagramHandler = (diagramRef, properties) => {
	updateDiagramProperty(diagramRef, 'update nodes properties', (model) => {
		model.nodeDataArray.forEach((nodeData) => {
			for (const [prop, value] of Object.entries(properties)) {
				model.setDataProperty(nodeData, prop, value)
			}
		})
	})
}

// Function to update link properties
export const updateLinks: DiagramHandler = (diagramRef, properties) => {
	updateDiagramProperty(diagramRef, 'update links properties', (model) => {
		;(model as go.GraphLinksModel).linkDataArray.forEach((linkData) => {
			for (const [prop, value] of Object.entries(properties)) {
				model.setDataProperty(linkData, prop, value)
			}
		})
	})
}

// Specific update functions using the generic handlers
export const updateNodeHeaderColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	color: string,
) => {
	updateNodes(diagramRef, { nodeHeaderColor: color })
}

export const toggleTableCode = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	isTableCodeVisible: boolean,
) => {
	updateNodes(diagramRef, { tableCodeVisibility: isTableCodeVisible })
}

export const toggleTableName = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	isTableNameVisible: boolean,
) => {
	updateNodes(diagramRef, { tableNameVisibility: isTableNameVisible })
}

export const toggleTableColumns = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	hasColumns: boolean,
) => {
	updateNodes(diagramRef, { tableColumnsVisibility: hasColumns })
}

export const updateNodeBodyColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	color: string,
) => {
	updateNodes(diagramRef, { nodeBodyColor: color })
}

export const updateLinksColor = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	color: string,
) => {
	updateLinks(diagramRef, { linksColor: color })
}

export const toggleLinksRoutingType = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	routingType: string,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram?.model?.startTransaction('change routing type')
	diagram?.links?.each((link) => {
		link.routing =
			routingType === 'orthogonal' ? Routing.Orthogonal : Routing.Normal
	})
	diagram?.model?.commitTransaction('change routing type')

	updateLinks(diagramRef, { routingType })
}

export const toggleLinksConstraintName = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	hasConstraintNames: boolean,
	dispatch: Dispatch<any>,
	node: StructureDto | null,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	const hasConstraintName = diagram.model.modelData.hasConstraintName
	diagram.model.startTransaction('toggleLinkLabels')
	diagram.model.setDataProperty(
		diagram.model.modelData,
		'hasConstraintName',
		!hasConstraintName,
	)

	dispatch(
		constraintNameSet({ data: hasConstraintNames, nodeId: node?.id as number }),
	)
	dispatch(saveDiagram(node as StructureDto))
	diagram.model.commitTransaction('toggleLinkLabels')
}

// New toggleGrid function to update the diagram grid visibility
export const toggleGrid = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	isGridVisible: boolean,
	dispatch: Dispatch<any>,
	node: StructureDto | null,
) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('toggle grid visibility')
	diagram.grid.visible = isGridVisible

	dispatch(
		gridVisibilitySet({ data: isGridVisible, nodeId: node?.id as number }),
	)
	dispatch(saveDiagram(node as StructureDto))
	diagram.commitTransaction('toggle grid visibility')
}

export const updateGridCellSize = (
	diagramRef: React.RefObject<ReactDiagram> | undefined,
	size: number,
	dispatch: Dispatch,
	nodeId: number,
) => {
	const diagram = diagramRef?.current?.getDiagram()

	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('update grid cell size')
	diagram.grid.gridCellSize = new go.Size(size, size)
	diagram.commitTransaction('update grid cell size')

	dispatch(gridSizeSet({ data: size, nodeId }))
}

export const updateGridLineColors = (
	diagramRef: RefObject<ReactDiagram> | undefined,
	{ color, type }: { color: string; type: string },
) => {
	const diagram = diagramRef?.current?.getDiagram()

	if (!diagram) {
		throw new Error('Diagram instance is null')
	}

	diagram.startTransaction('update grid colors')

	const updateLineColor = (lineName: string, color: string) => {
		const line = diagram.grid.findObject(lineName) as go.Shape
		if (line && color) {
			line.stroke = color
		}
	}

	switch (type) {
		case 'GRID_H_LINE_INPUT':
			updateLineColor('HorizontalLine', color)
			break
		case 'GRID_V_LINE_INPUT':
			updateLineColor('VerticalLine', color)
			break
		case 'GRID_INTERVAL_H_LINE_INPUT':
			updateLineColor('IntervalHorizontalLine', color)
			break
		case 'GRID_INTERVAL_V_LINE_INPUT':
			updateLineColor('IntervalVerticalLine', color)
			break
		default:
			throw new Error(`Unknown line type: ${type}`)
	}

	diagram.commitTransaction('update grid colors')
}
