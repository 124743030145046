import {
	faLock,
	faPen,
	faTimes,
	faTrash,
	faUnlock,
	faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { useEffect } from 'react'
import styled from 'styled-components'

import { Button, DialogWrapper } from '@/components'
import { StructureObjectDto } from '@/endpoints/models'
import {
	useAppContext,
	useAppDispatch,
	useAppStore,
	useAuthContext,
} from '@/hooks'
import { lockNode } from '@/store/modules/node/actions'
import {
	cancelEditingTab,
	editTab,
	setTabPermanent,
} from '@/store/modules/tab/actions'
import { hasNodePermissions, isEditable, isLocked, isLockedByMe } from '@/utils'

import { CancelEditModal } from '../CancelEditModal'
import { DeleteModal } from '../DeleteModal'
import { useDetailTabContext } from '../DetailTab/context/DetailTabContext'
import { NodeButtons } from '../NodeButtons'
import { PermissionsButton } from '../PermissionsButton'
import { PushModal } from '../PushModal/PushModal'
import { UnlockModal } from '../UnlockModal'
import { EditableNodeActionsProps } from './types'

export const EditableNodeActions = ({
	node,
	editMode,
	dirty,
	buttons,
	moreButtons,
	onCancel,
	onEdit,
	contentCustom,
	afterPush,
	beforePush,
	customDiagramWarning,
}: EditableNodeActionsProps) => {
	const { t } = useAppContext()
	const auth = useAuthContext()

	const dispatch = useAppDispatch()
	const user = useAppStore((state) => state.auth.user)

	const {
		state: { version },
	} = useDetailTabContext()

	const edit = async () => {
		dispatch(setTabPermanent(node))
		dispatch(lockNode(node))
		await onEdit(node)
		dispatch(editTab(node))
	}

	const lock = () => {
		dispatch(lockNode(node))
		dispatch(setTabPermanent(node))
	}

	useEffect(() => {
		if (editMode && !isEditable(node, user)) {
			dispatch(cancelEditingTab(node))
		}
	}, [dispatch, editMode, node, user])

	const canDelete =
		node.type === StructureObjectDto.TypeEnum.SYSTEM
			? auth.canDeleteSystem()
			: isEditable(node, user)

	return (
		<>
			{!editMode && (
				<>
					{contentCustom}
					<NodeButtons
						buttons={
							<>
								{typeof buttons === 'function'
									? buttons({
											isLocked: isLocked(node),
											isInEditMode: editMode,
											isLockedByMe: !!isLockedByMe(node, user),
											isEditable: !!isEditable(node, user),
										})
									: buttons}
								<Button
									disabled={!isEditable(node, user) || version !== undefined}
									onClick={edit}
									icon={faPen}
									schema="primary"
									coloredIcon={true}
								>
									{t('EDIT')}
								</Button>

								{isLocked(node) && auth.canForceUnlock() && (
									<DialogWrapper
										dialog={(opened, onClose) =>
											opened && <UnlockModal node={node} onClose={onClose} />
										}
									>
										{(onClick) => (
											<Button
												disabled={!node.canEdit}
												onClick={onClick}
												icon={faUnlock}
												schema="warn"
												coloredIcon={true}
											>
												{t('UNLOCK')}
											</Button>
										)}
									</DialogWrapper>
								)}

								{!isLocked(node) && (
									<Button
										disabled={!node.canEdit}
										onClick={lock}
										icon={faLock}
										schema="warn"
										coloredIcon={true}
									>
										{t('LOCK')}
									</Button>
								)}
							</>
						}
						moreButtons={
							((auth.canManageUsers() && hasNodePermissions(node.type)) ||
								moreButtons ||
								canDelete) && (
								<>
									{typeof moreButtons === 'function'
										? moreButtons({
												isLocked: isLocked(node),
												isInEditMode: editMode,
												isLockedByMe: !!isLockedByMe(node, user),
												isEditable: !!isEditable(node, user),
											})
										: moreButtons}

									{auth.canManageUsers() && hasNodePermissions(node.type) && (
										<PermissionsButton node={node} />
									)}

									{canDelete && (
										<DialogWrapper
											dialog={(opened, onClose) =>
												opened && <DeleteModal node={node} onClose={onClose} />
											}
										>
											{(onClick) => (
												<Button
													icon={faTrash}
													onClick={onClick}
													schema="danger"
													coloredIcon={true}
												>
													{t('DELETE')}
												</Button>
											)}
										</DialogWrapper>
									)}
								</>
							)
						}
					/>
				</>
			)}
			{editMode && (
				<NodeButtons
					buttons={
						<>
							{dirty !== undefined && (
								<DirtyInfo>
									{t(dirty ? 'AUTOSAVE_DIRTY' : 'AUTOSAVE_SAVED')}
								</DirtyInfo>
							)}
							{contentCustom}
							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<PushModal
											node={node}
											onClose={onClose}
											onExitEditMode={onCancel}
											beforePush={beforePush}
											afterPush={afterPush}
										/>
									)
								}
							>
								{(onClick) => (
									<Button
										icon={faUpload}
										onClick={onClick}
										schema="success"
										coloredIcon={true}
									>
										{t('PUSH')}
									</Button>
								)}
							</DialogWrapper>

							<DialogWrapper
								dialog={(opened, onClose) =>
									opened && (
										<CancelEditModal
											node={node}
											onClose={onClose}
											onConfirm={onCancel}
											customDiagramWarning={customDiagramWarning}
										/>
									)
								}
							>
								{(onClick) => (
									<Button
										icon={faTimes}
										onClick={onClick}
										schema="danger"
										coloredIcon={true}
									>
										{t('CANCEL')}
									</Button>
								)}
							</DialogWrapper>
						</>
					}
				/>
			)}
		</>
	)
}

const DirtyInfo = styled.div`
	margin: auto 12px auto 6px;
`
