import * as go from 'gojs'

import { AllKeysFromNodes } from '@/types/diagrams'

import { DiagramTemplateType } from '../enums'
import { useCreateNodeTempLayout } from './nodeTempLayout'
import { useNodeTempSettings } from './useNodeTempSettings'

export const getCommentAndDescription = (
	allKeysFromNodes: AllKeysFromNodes[],
	nodeId: string,
): { comment: string; description: string } => {
	const node = allKeysFromNodes.find((node) => node.id.toString() === nodeId)

	if (!node) {
		return { comment: '', description: '' }
	}

	return {
		comment: node.comment || '',
		description: node.description || '',
	}
}

const createDefaultNode = new go.Node('Auto').add(
	new go.Shape('rectangle', { fill: 'red', width: 150, height: 60 }),
	new go.TextBlock('Goodbye!', { margin: 5 }).bind(new go.Binding('text')),
)

export const useSetupNodeTemplates = () => {
	const nodeTemplatesMap = new go.Map<string, go.Part>()

	const createNodeTempLayout = useCreateNodeTempLayout()
	const noteTempSettings = useNodeTempSettings()

	const tableNodeTemp = new go.Node('Vertical', {
		layerName: 'Foreground',
		isShadowed: true,
		shadowBlur: 7,
		shadowOffset: new go.Point(0, 4),
		shadowColor: 'rgba(0, 0, 0, 0.2)',
	})
		.bind(
			new go.Binding('location', 'loc', go.Point.parse).makeTwoWay(
				go.Point.stringify,
			),
		)
		.bind(
			new go.Binding('opacity', 'isDeleted', (isDeleted) =>
				isDeleted ? 0.5 : 1,
			),
		)
		.set(noteTempSettings)
		.add(createNodeTempLayout)

	nodeTemplatesMap.add('', createDefaultNode)
	nodeTemplatesMap.add(DiagramTemplateType.Table, tableNodeTemp)

	return nodeTemplatesMap
}
