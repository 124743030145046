import { Modal } from '@/components/Modal/Modal'

import { ConfirmationDialogProps } from '../types'

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
	open,
	title,
	description,
	onClose,
	footer,
}) => {
	return (
		<Modal
			onClose={onClose}
			open={open}
			header={title}
			zIndex={9999}
			stickyFooter
			contentStyle={{
				width: 700,
				height: 350,
			}}
			footer={footer}
		>
			<div className="flex h-full items-center">{description}</div>
		</Modal>
	)
}
