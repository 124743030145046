import { ReactDiagram } from 'gojs-react'
import { useEffect } from 'react'

import { DiagramModals } from '@/components/Diagram'
import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import { useAppSelector } from '@/hooks'

import { DiagramPropertiesPanel } from '../DiagramPropertiesPanel'
import { useInitDiagram, useReactDiagramDrop } from '../hooks'
import { Container, DiagramContainer } from '../styles'
import { DiagramWrapperProps } from '../types'
import { debugModelData } from '../utils'
import { DiagramControls } from './DiagramControls'

export const DiagramWrapper = ({
	linkDataArray,
	skipsDiagramUpdate,
	onDiagramEvent,
	onModelChange,
}: DiagramWrapperProps) => {
	const { diagramRef, node, isEditMode, exportSvg, exportPng } =
		useDiagramContext()

	const thisDiagram = useAppSelector(
		(state) => state.diagram?.diagrams[node?.id as number]?.form.graph,
	)

	const { handleDrop, handleDragOver, handleDragLeave } = useReactDiagramDrop()

	const { initDiagram } = useInitDiagram()

	const nodeDataArray = thisDiagram?.nodeDataArray || []

	useEffect(() => {
		const goJsDiagram = diagramRef?.current?.getDiagram()
		if (goJsDiagram) {
			goJsDiagram?.addDiagramListener('ChangedSelection', onDiagramEvent)

			// Cleanup function to be called on unmount
			return () => {
				goJsDiagram?.removeDiagramListener('ChangedSelection', onDiagramEvent)
			}
		}
	}, [diagramRef, onDiagramEvent])

	useEffect(() => {
		if (diagramRef?.current) {
			debugModelData(diagramRef)
		}
	}, [diagramRef])

	return (
		<Container>
			<DiagramControls onExportPng={exportPng} onExportSvg={exportSvg} />
			<DiagramContainer
				onDrop={isEditMode ? handleDrop : undefined}
				onDragEnter={handleDragOver}
				onDragOver={handleDragOver}
				onDragLeave={handleDragLeave}
				onDragEnd={handleDragLeave}
			>
				<ReactDiagram
					ref={diagramRef}
					divClassName="diagram"
					initDiagram={initDiagram}
					nodeDataArray={nodeDataArray}
					linkDataArray={linkDataArray}
					onModelChange={onModelChange}
					skipsDiagramUpdate={skipsDiagramUpdate}
				/>
				<DiagramPropertiesPanel
					diagramRef={diagramRef}
					saveProperties={(properties) => onModelChange(undefined, properties)}
					nodeId={node?.id}
				/>
			</DiagramContainer>
			<DiagramModals />
		</Container>
	)
}
