import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import styled from 'styled-components'

import Button from '@/components/Button/Button'
import { Loader } from '@/components/Loader/Loader'
import { Modal } from '@/components/Modal/Modal'
import { CheckBoxFormField } from '@/components/UberForm'
import { useTabContext } from '@/context/TabContext/TabContext'
import { StructureDto, StructureObjectDto } from '@/endpoints/models'
import { useAppContext, useAppDispatch } from '@/hooks'
import { revertHistory } from '@/store/modules/history/actions'
import { unlockNode } from '@/store/modules/node/actions'
import { cancelEditingTab } from '@/store/modules/tab/actions'

interface CancelEditModalProps {
	customDiagramWarning?: React.ReactNode
	node: StructureDto
	onClose: () => void
	onConfirm: (node: StructureObjectDto) => Promise<void>
}

export const CancelEditModal = ({
	node,
	onConfirm,
	onClose,
	customDiagramWarning,
}: CancelEditModalProps) => {
	const { t } = useAppContext()
	const dispatch = useAppDispatch()
	const { onSaveError } = useTabContext()
	const [doUnlockNode, setUnlockNode] = useState(true)
	const [loading, setLoading] = useState(false)

	const handleConfirm = async () => {
		setLoading(true)

		try {
			await dispatch(revertHistory(node.id))
			await onConfirm(node)
			dispatch(cancelEditingTab(node))

			if (doUnlockNode) {
				await dispatch(unlockNode(node))
			}
		} catch (e) {
			onSaveError(e)
		}

		setLoading(false)
		onClose()
	}

	return (
		<Modal
			open={true}
			contentStyle={{
				width: '400px',
			}}
			header={t('CANCEL_EDIT_HEADER', [node.name])}
			footer={(close) => (
				<>
					<Button
						icon={faTrash}
						schema="danger"
						onClick={handleConfirm}
						isLoading={loading}
					>
						{t('YES')}
					</Button>
					<Button schema="transparent" onClick={close} icon={faTimes}>
						{t('NO')}
					</Button>
				</>
			)}
			onClose={onClose}
		>
			<div>
				<Loader loaded={!loading} $absolute />

				<Description>{t('CANCEL_EDIT_MESSAGE', [node.name])}</Description>

				{customDiagramWarning && customDiagramWarning}

				<CheckBoxFormField
					showTitlePlaceholder={false}
					name="exitEditMode"
					onChange={(value: any) => setUnlockNode(value as boolean)}
					title={t('UNLOCK_NODE')}
					initialValue={doUnlockNode}
				/>
			</div>
		</Modal>
	)
}

const Description = styled.p`
	margin-bottom: 20px;
`
