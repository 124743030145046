import { useEffect, useState } from 'react'

import { GraphProps } from '@/store/slices/diagram/types'

import { LocalDiagramPropertiesProps } from '../types'

// Custom hook to sync local state
export const useLocalDiagramProperties = (diagramProperties: GraphProps) => {
	const [localProperties, setLocalProperties] =
		useState<LocalDiagramPropertiesProps>()

	useEffect(() => {
		setLocalProperties({
			nodeHeaderColor: diagramProperties?.properties?.node?.header?.color,
			nodeBodyColor: diagramProperties?.properties?.node?.body?.color,
			linksColor: diagramProperties?.properties?.links?.color,
			linksRoutingType: diagramProperties?.properties?.links?.routingType,
			isGridVisible: diagramProperties?.properties?.grid?.isVisible,
			isTableCodeVisible:
				diagramProperties?.properties?.displayOptions?.tableCode,
			isTableNameVisible:
				diagramProperties?.properties?.displayOptions?.tableName,
			hasColumns: diagramProperties?.properties?.displayOptions?.tableColumns,
			hasConstraintNames:
				diagramProperties?.properties?.displayOptions?.tableConstraintName,
			gridCellSize: diagramProperties?.properties?.grid?.cellSize,
			gridHorizontalLineColor:
				diagramProperties?.properties?.grid?.lineColors?.horizontal,
			gridVerticalLineColor:
				diagramProperties?.properties?.grid?.lineColors?.vertical,
			gridIntervalVLineColor:
				diagramProperties?.properties?.grid?.lineColors?.intervalVertical,
			gridIntervalHLineColor:
				diagramProperties?.properties?.grid?.lineColors?.intervalHorizontal,
		})
	}, [diagramProperties])

	return { localProperties }
}
