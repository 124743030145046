import { ReactDiagram } from 'gojs-react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { downloadImage, downloadSvg } from '@/utils/dom'

type DiagramOptions = {
	isEditMode?: boolean
}

export const useReactDiagram = (options?: DiagramOptions) => {
	const { isEditMode } = options || {}

	/**
	 * Ref to keep a reference to the Diagram component, which provides access to the GoJS diagram via getDiagram().
	 */
	const diagramRef = useRef<ReactDiagram>(null)
	const [initialized, setInitialized] = useState(false)

	useEffect(() => {
		if (diagramRef.current) {
			setInitialized(true)
			const diagram = diagramRef.current?.getDiagram()
			diagram?.set({ isReadOnly: !isEditMode })
		} else {
			setInitialized(false)
		}
	}, [isEditMode])

	const exportPng = useCallback(() => {
		if (diagramRef.current) {
			const diagram = diagramRef.current.getDiagram()
			const image = diagram?.makeImage()
			image && downloadImage(image, 'diagram')
		}
	}, [])

	const exportSvg = useCallback(() => {
		if (diagramRef.current) {
			const diagram = diagramRef.current.getDiagram()
			const svg = diagram?.makeSvg()
			svg && downloadSvg(svg, 'diagram')
		}
	}, [])

	const makeAutoLayout = useCallback(() => {
		if (diagramRef.current) {
			const diagram = diagramRef.current.getDiagram()
			diagram?.layout.invalidateLayout()
		}
	}, [])

	return {
		diagramRef,
		exportPng,
		exportSvg,
		makeAutoLayout,
		initialized, // expose initialized state
	}
}
