import go from 'gojs'
import { ReactDiagram } from 'gojs-react'
import { RefObject } from 'react'

import { StructureDtoRedux } from '@/store/modules/node'
import colors from '@/styles/diagramColors'
import { NativeMap } from '@/utils'

import { ValidationError, ValidationErrorType } from './types'

export const getNodePath = (
	allNodesFromTree: NativeMap<StructureDtoRedux>,
	nodeId: number | undefined,
): string => {
	const acc: string[] = []

	const buildPath = (currentId: number | undefined) => {
		const node = allNodesFromTree[currentId as number]
		if (!node) {
			return
		}
		if (node.parentStructureId) {
			buildPath(node.parentStructureId)
		}
		if (node.name !== undefined) {
			acc.push(node.name)
		}
	}

	buildPath(nodeId)
	return acc.join(' > ')
}

/**

Marks validation errors in the diagram. For nodes and links

related to the error, the 'changed' property is set to true.

@param diagram - The current instance of the diagram.

@param validationErrors - Array of validation errors.
*/
export const markChanges = (
	diagram: ReactDiagram | null,
	validationErrors: ValidationError[],
) => {
	if (diagram) {
		const instanceDiagram = diagram.getDiagram()
		const model = instanceDiagram?.model as go.GraphLinksModel

		if (model && instanceDiagram) {
			instanceDiagram.startTransaction('mark changes')

			validationErrors.forEach((error) => {
				const { type, nodeId } = error

				// Mark changed nodes
				const node = model.findNodeDataForKey(nodeId)
				if (
					node &&
					(type === ValidationErrorType.NODE_NOT_EXIST ||
						type === ValidationErrorType.TEXT_MISMATCH)
				) {
					model.setDataProperty(node, 'hasChanged', true)
					model.setDataProperty(node, 'bodyColor', colors.node.hasChanged)
				}

				// Mark changed links
				const link = model.findLinkDataForKey(nodeId)
				if (
					link &&
					(type === ValidationErrorType.NODE_NOT_EXIST ||
						type === ValidationErrorType.TEXT_MISMATCH ||
						type === ValidationErrorType.LINK_INVALID)
				) {
					model.setDataProperty(link, 'hasChanged', true)
				}
			})

			instanceDiagram.commitTransaction('mark changes')
		}
	}
}

export const serializeDiagramToSvg = (diagram: go.Diagram) => {
	const diagramSvg = diagram.makeSvg()
	return new XMLSerializer().serializeToString(diagramSvg as SVGAElement)
}

export const getModelAsObject = (diagram: go.Diagram) => {
	const modelAsJson = diagram?.model?.toJson()
	return modelAsJson ? JSON.parse(modelAsJson) : undefined
}

export const debugModelData = (diagramRef: RefObject<ReactDiagram> | null) => {
	const diagram = diagramRef?.current?.getDiagram()
	if (!diagram) {
		throw new Error('Diagram instance is null')
	}
	console.log('Model Data via useEffect:', diagram.model.modelData)
}
