import { useAppContext } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import Button from '../../Button/Button'
import { Controls } from '../styles'

interface DiagramControlsProps {
	onExportPng?: () => void
	onExportSvg?: () => void
}

export const DiagramControls = ({
	onExportPng,
	onExportSvg,
}: DiagramControlsProps) => {
	const { t } = useAppContext()
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	return (
		<Controls>
			<Button schema="default" onClick={onExportPng} disabled={!isEditMode}>
				{t('EXPORT_PNG')}
			</Button>
			<Button schema="default" onClick={onExportSvg} disabled={!isEditMode}>
				{t('EXPORT_SVG')}
			</Button>
		</Controls>
	)
}
