import { debounce } from 'lodash'
import { ChangeEvent, useMemo } from 'react'

import { updateSpecificLink } from '@/components/Diagram/handlers/linkHandlers'
import { useAppContext, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'
import { RootState } from '@/store'

import { LinkPropertiesProps } from '../types'
import {
	DiagramPropertiesContent,
	PropertyType,
} from './DiagramPropertiesContent'

export const LinkProperties = ({
	diagramRef,
	propertiesWidth,
	handlePropertiesTitle,
	nodeId,
}: LinkPropertiesProps) => {
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const getAllNodesFromTree = useAppSelector(
		(state: RootState) => state?.node?.nodes,
	)

	const selectedLink = useAppSelector(
		(state) =>
			state.diagram.diagrams[nodeId as number]?.form.graph.inspector
				.inspectedData,
	)

	const thisLink = useAppSelector((state) =>
		state.diagram.diagrams[nodeId as number].form.graph.linkDataArray.find(
			(link) => link.key === selectedLink?.key,
		),
	)

	const { key, text, to, from, linksColor, hasName } = thisLink || {}

	const { t } = useAppContext()
	handlePropertiesTitle(t('DIAGRAM_LINK_PROPERTIES'))

	const debouncedUpdateSpecificLink = debounce(updateSpecificLink, 500)

	const onChange = (e: ChangeEvent<HTMLInputElement>, inputType: string) => {
		if (inputType === 'SPECIFIC_LINK_COLOR') {
			return debouncedUpdateSpecificLink(
				diagramRef,
				inputType,
				selectedLink?.key as number,
				e.target?.value,
			)
		}

		return debouncedUpdateSpecificLink(
			diagramRef,
			inputType,
			selectedLink?.key as number,
			e.target?.checked,
		)
	}

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_ID'),
				contentValue: key,
				type: PropertyType.Info,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_TEXT'),
				contentValue: text,
				type: PropertyType.Info,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_FROM'),
				contentValue: getAllNodesFromTree[to as number]?.name,
				type: PropertyType.Info,
			},
			{
				id: 4,
				label: t('DIAGRAM_NODE_TO'),
				contentValue: getAllNodesFromTree[from as number]?.name,
				type: PropertyType.Info,
			},
			{
				id: 5,
				label: t('DIAGRAM_NODE_TYPE'),
				contentValue: 'Link',
				type: PropertyType.Info,
			},
			{
				id: 6,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={linksColor}
						onChange={(event) => onChange(event, 'SPECIFIC_LINK_COLOR')}
						disabled={!isEditMode}
					/>
				),
				inputType: 'color',
				type: PropertyType.Constraint,
			},
			{
				id: 13,
				label: t('DIAGRAM_TABLE_CONSTRAINT_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={hasName}
						onChange={(event) => onChange(event, 'SPECIFIC_LINK_NAME')}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Constraint,
			},
		],
		[getAllNodesFromTree, isEditMode, onChange, thisLink, t],
	)

	return (
		<DiagramPropertiesContent
			contents={contents}
			propertiesWidth={propertiesWidth}
		/>
	)
}
