import { memo, useMemo } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'

import { DomainDto, StructureDto } from '@/endpoints/models'
import { SystemData } from '@/endpoints/schemas'
import { useAppContext } from '@/hooks'
import { VersionsTab } from '@/pages/User/pages/Home/components/VersionsTab/VersionsTab'
import { DeploymentPackages } from '@/pages/User/pages/Home/pages/SystemDetail/pages/DeploymentPackages/DeploymentPackages'
import { selectSystemTab } from '@/store/modules/system/actions'
import { OpenedSystemData, SystemTab } from '@/store/modules/system/types'
import { UpdateDeepPartial } from '@/store/utils'
import { GrowTabs } from '@/styles'

import { Dependencies, Validation } from '../../../components'
import { Constants, Environments, Lookups, Overview, Users } from '../pages'

type SystemTabsComponentProps = ReturnType<typeof mapDispatchToProps> & {
	data: OpenedSystemData
	domains: DomainDto[]
	editMode: boolean
	node: StructureDto
	onChange: (v: UpdateDeepPartial<SystemData>) => void
	systemNodeId: number
}

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators({ selectSystemTab }, dispatch)

const SystemTabsComponent = ({
	selectSystemTab,
	node,
	data,
	editMode,
	onChange,
	domains,
	systemNodeId,
}: SystemTabsComponentProps) => {
	const { t } = useAppContext()

	const tabs = useMemo(
		() => [
			{
				id: SystemTab.Overview,
				title: t('TAB_OVERVIEW'),
				content: (
					<Overview
						key={SystemTab.Overview}
						node={node}
						data={data}
						editMode={editMode}
						onChange={onChange}
						systemNodeId={systemNodeId}
					/>
				),
			},
			{
				id: SystemTab.Constants,
				title: t('TAB_CONSTANTS'),
				content: (
					<Constants
						key={SystemTab.Constants}
						node={node}
						data={data}
						domains={domains}
						onChange={onChange}
					/>
				),
			},
			{
				id: SystemTab.Lookups,
				title: t('TAB_LOOKUPS'),
				content: (
					<Lookups
						key={SystemTab.Lookups}
						node={node}
						data={data}
						domains={domains}
						onChange={onChange}
					/>
				),
			},
			{
				id: SystemTab.Environments,
				title: t('TAB_ENVIRONMENTS'),
				content: (
					<Environments
						key={SystemTab.Environments}
						node={node}
						data={data}
						onChange={onChange}
					/>
				),
			},
			{
				id: SystemTab.Releases,
				title: t('RELEASES'),
				content: (
					<DeploymentPackages
						key={SystemTab.Releases}
						node={node}
						editMode={node.canEdit}
						data={data}
					/>
				),
			},
			{
				id: SystemTab.Users,
				title: t('TAB_USERS'),
				content: (
					<Users
						key={SystemTab.Users}
						node={node}
						data={data}
						onChange={onChange}
					/>
				),
			},
			{
				id: SystemTab.Validation,
				title: t('TAB_VALIDATION'),
				content: <Validation key={SystemTab.Validation} node={node} />,
			},
			{
				id: SystemTab.Dependencies,
				title: t('DEPENDENCIES'),
				content: (
					<Dependencies
						key={SystemTab.Dependencies}
						node={node}
						editMode={editMode}
					/>
				),
			},
			{
				id: SystemTab.Version,
				title: t('VERSIONS'),
				content: <VersionsTab />,
			},
		],
		[data, domains, editMode, node, onChange, systemNodeId, t],
	)

	return (
		<GrowTabs
			onChange={(tab) => selectSystemTab(node, tab.id as SystemTab)}
			selectedTabId={data.tab}
			tabs={tabs}
		/>
	)
}

export const SystemTabs = memo(
	connect(null, mapDispatchToProps)(SystemTabsComponent),
)
