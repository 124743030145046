import { useCallback, useMemo } from 'react'
import {
	computeImpactDomainChange,
	createDomain,
	updateDomain,
} from 'src/endpoints'

import { useApiRequest } from '@/endpoints/hooks'
import { DomainDto } from '@/endpoints/models'
import { useAppContext } from '@/hooks'
import { useConfirmation } from '@/pages/App/components/ConfirmationService'

export const useConfirmationDomain = () => {
	const { t } = useAppContext()
	const confirm = useConfirmation()
	const request = useApiRequest()

	const onConfirmationDomain = useCallback(
		async (
			domain: DomainDto,
			postProcess: (domain: DomainDto | null) => void,
		) => {
			if (domain?.id) {
				const impact = await request(
					computeImpactDomainChange(domain?.id || 0, domain),
				)

				if (impact.data?.affectedElements?.COLUMN) {
					const description = (
						<>
							<div>
								{t('DOMAIN_UPDATE_AFFECTED_MESSAGE', [
									impact.data?.affectedElements?.COLUMN || 0,
									impact.data?.affectedElements?.TABLE || 0,
								])}
							</div>
						</>
					)

					confirm({
						catchOnCancel: true,
						title: t('DOMAIN_UPDATE_CONFIRM_TITLE'),
						description: description,
					})
						.then((options) => {
							if (options?.cancel) {
								postProcess(domain)

								return
							}

							request(
								updateDomain(domain?.id || 0, domain, { updateElements: true }),
							).then((value) => postProcess(value.data))
						})
						.catch(() => {
							request(
								updateDomain(domain?.id || 0, domain, {
									updateElements: false,
								}),
							).then((value) => postProcess(value.data))
						})
				} else {
					request(
						updateDomain(domain?.id || 0, domain, { updateElements: false }),
					).then((value) => postProcess(value.data))
				}
			} else {
				await request(createDomain(domain)).then((value) =>
					postProcess(value.data),
				)
			}
		},
		[confirm, request, t],
	)

	return useMemo(() => ({ onConfirmationDomain }), [onConfirmationDomain])
}
