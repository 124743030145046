import { cloneDeep, debounce, merge } from 'lodash'
import { useMemo } from 'react'

import { useDiagramContext } from '@/components/Diagram/context/DiagramContext'
import {
	DiagramPropertiesContent,
	PropertyType,
} from '@/components/Diagram/DiagramPropertiesPanel/DiagramPropertiesContent'
import { RoutingTypeSelector } from '@/components/Diagram/DiagramPropertiesPanel/RoutingTypeSelector'
import { useLocalDiagramProperties } from '@/components/Diagram/hooks/useLocalDiagramProperties'
import { useAppContext, useAppDispatch, useAppSelector } from '@/hooks'
import { useDetailTabContext } from '@/pages/User/pages/Home/components/DetailTab'

import {
	toggleGrid,
	toggleLinksConstraintName,
	toggleLinksRoutingType,
	toggleTableCode,
	toggleTableColumns,
	toggleTableName,
	updateGridCellSize,
	updateGridLineColors,
	updateLinksColor,
	updateNodeBodyColor,
	updateNodeHeaderColor,
} from '../handlers'
import { DiagramPropertiesProps, LineColorType } from '../types'

export const DiagramProperties = ({
	handlePropertiesTitle,
	diagramRef,
	saveProperties,
	nodeId,
}: DiagramPropertiesProps) => {
	const { t } = useAppContext()
	const {
		state: { editMode: isEditMode },
	} = useDetailTabContext()

	const { node } = useDiagramContext()

	const dispatch = useAppDispatch()

	handlePropertiesTitle(t('DIAGRAM_PROPERTIES'))

	const thisDiagram = useAppSelector(
		(state) => state.diagram.diagrams[nodeId].form.graph,
	)

	const { localProperties } = useLocalDiagramProperties(thisDiagram)

	const saveDebounced = debounce((updatedProps) => {
		const newProperties = merge({}, thisDiagram?.properties, updatedProps)
		saveProperties?.(newProperties)
	}, 200)

	const handleGridLineColorChange = (type: LineColorType, color: string) => {
		const updatedProps = cloneDeep(thisDiagram?.properties)

		const payload = {
			...updatedProps,
			grid: {
				...updatedProps.grid,
				lineColors: { ...updatedProps.grid.lineColors, [type]: color },
			},
		}

		saveDebounced(payload)
	}
	const handleInputChange = (inputType: string, value: any) => {
		switch (inputType) {
			case 'NODE_HEADER_INPUT':
				saveDebounced({ node: { header: { color: value } } })
				return updateNodeHeaderColor(diagramRef, value)
			case 'TABLE_CODE_INPUT':
				saveDebounced({ displayOptions: { tableCode: value } })
				return toggleTableCode(diagramRef, value)
			case 'TABLE_NAME_INPUT':
				saveDebounced({ displayOptions: { tableName: value } })
				return toggleTableName(diagramRef, value)
			case 'TABLE_COLUMNS_INPUT':
				saveDebounced({ displayOptions: { tableColumns: value } })
				return toggleTableColumns(diagramRef, value)
			case 'TABLE_CONSTRAINT_NAME_INPUT':
				return toggleLinksConstraintName(diagramRef, value, dispatch, node)
			case 'NODE_BODY_INPUT':
				saveDebounced({ node: { body: { color: value } } })
				return updateNodeBodyColor(diagramRef, value)
			case 'LINKS_COLOR_INPUT':
				saveDebounced({ links: { color: value } })
				return updateLinksColor(diagramRef, value)
			case 'ROUTING_TYPE_INPUT':
				saveDebounced({ links: { routingType: value } })
				return toggleLinksRoutingType(diagramRef, value)
			case 'GRID_TOGGLE_INPUT':
				return toggleGrid(diagramRef, value, dispatch, node)
			case 'GRID_SIZE_INPUT':
				return updateGridCellSize(diagramRef, value, dispatch, nodeId)
			case 'GRID_H_LINE_INPUT':
				handleGridLineColorChange('horizontal', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_H_LINE_INPUT',
				})
			case 'GRID_V_LINE_INPUT':
				handleGridLineColorChange('vertical', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_V_LINE_INPUT',
				})
			case 'GRID_INTERVAL_H_LINE_INPUT':
				handleGridLineColorChange('intervalHorizontal', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_INTERVAL_H_LINE_INPUT',
				})

			case 'GRID_INTERVAL_V_LINE_INPUT':
				handleGridLineColorChange('intervalVertical', value)

				return updateGridLineColors(diagramRef, {
					color: value,
					type: 'GRID_INTERVAL_V_LINE_INPUT',
				})
			default:
				break
		}
	}

	const {
		nodeHeaderColor,
		nodeBodyColor,
		linksColor,
		isGridVisible,
		gridCellSize,
		gridHorizontalLineColor,
		gridVerticalLineColor,
		gridIntervalVLineColor,
		gridIntervalHLineColor,
		isTableCodeVisible,
		isTableNameVisible,
		linksRoutingType,
		hasColumns,
		hasConstraintNames,
	} = localProperties || {}

	const contents = useMemo(
		() => [
			{
				id: 1,
				label: t('DIAGRAM_NODE_HEADER_COLOR'),
				contentValue: (
					<input
						type="color"
						value={nodeHeaderColor}
						onChange={(e) =>
							handleInputChange('NODE_HEADER_INPUT', e.target.value)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 2,
				label: t('DIAGRAM_NODE_COLUMNS_BACKGROUND'),
				contentValue: (
					<input
						type="color"
						value={nodeBodyColor}
						onChange={(e) =>
							handleInputChange('NODE_BODY_INPUT', e.target.value)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Table,
			},
			{
				id: 3,
				label: t('DIAGRAM_NODE_CONSTRAINT_COLOR'),
				contentValue: (
					<input
						type="color"
						value={linksColor}
						onChange={(e) =>
							handleInputChange('LINKS_COLOR_INPUT', e.target.value)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Constraint,
			},
			{
				id: 4,
				label: t('DIAGRAM_GRID_SWITCH'),
				contentValue: (
					<input
						type="checkbox"
						checked={isGridVisible}
						onChange={() =>
							handleInputChange('GRID_TOGGLE_INPUT', !isGridVisible)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Grid,
				isGrouped: true,
			},
			...(thisDiagram?.properties?.grid?.isVisible
				? [
						{
							id: 5,
							label: t('DIAGRAM_GRID_CELL_SIZE'),
							contentValue: (
								<input
									type="range"
									min="5"
									max="50"
									value={gridCellSize || 10}
									onChange={(e) =>
										handleInputChange('GRID_SIZE_INPUT', Number(e.target.value))
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
							isGrouped: true,
						},
						{
							id: 6,
							label: t('DIAGRAM_GRID_HORIZONTAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridHorizontalLineColor}
									onChange={(e) =>
										handleInputChange('GRID_H_LINE_INPUT', e.target.value)
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 7,
							label: t('DIAGRAM_GRID_VERTICAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridVerticalLineColor}
									onChange={(e) =>
										handleInputChange('GRID_V_LINE_INPUT', e.target.value)
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 8,
							label: t('DIAGRAM_GRID_INTERVAL_HORIZONTAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridIntervalHLineColor}
									onChange={(e) =>
										handleInputChange(
											'GRID_INTERVAL_H_LINE_INPUT',
											e.target.value,
										)
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
						{
							id: 9,
							label: t('DIAGRAM_GRID_INTERVAL_VERTICAL_LINE'),
							contentValue: (
								<input
									type="color"
									value={gridIntervalVLineColor}
									onChange={(e) =>
										handleInputChange(
											'GRID_INTERVAL_V_LINE_INPUT',
											e.target.value,
										)
									}
									disabled={!isEditMode}
								/>
							),
							type: PropertyType.Grid,
						},
					]
				: []),
			{
				id: 10,
				label: t('DIAGRAM_TABLE_CODE'),
				contentValue: (
					<input
						type="checkbox"
						checked={isTableCodeVisible}
						onChange={() =>
							handleInputChange('TABLE_CODE_INPUT', !isTableCodeVisible)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 11,
				label: t('DIAGRAM_TABLE_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={isTableNameVisible}
						onChange={() =>
							handleInputChange('TABLE_NAME_INPUT', !isTableNameVisible)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 12,
				label: 'Show columns',
				contentValue: (
					<input
						type="checkbox"
						checked={hasColumns}
						onChange={() =>
							handleInputChange('TABLE_COLUMNS_INPUT', !hasColumns)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Display,
			},
			{
				id: 13,
				label: t('DIAGRAM_TABLE_CONSTRAINT_NAME'),
				contentValue: (
					<input
						type="checkbox"
						checked={hasConstraintNames}
						onChange={(event) =>
							handleInputChange(
								'TABLE_CONSTRAINT_NAME_INPUT',
								event.target.checked,
							)
						}
						disabled={!isEditMode}
					/>
				),
				type: PropertyType.Constraint,
			},
		],
		[isEditMode, localProperties, t],
	)

	return (
		<>
			<DiagramPropertiesContent contents={contents} propertiesWidth={350} />
			<RoutingTypeSelector
				routingType={linksRoutingType}
				onChange={(e) =>
					handleInputChange('ROUTING_TYPE_INPUT', e.target.value)
				}
				t={t}
			/>
		</>
	)
}
