import { appConfig } from '@/config'
import { PostTaskTypeBody } from '@/pages/User/pages/Settings/pages/TechnicalColumns/types'

export const postTaskType = async (
	type: string,
	token: string | null,
	body: PostTaskTypeBody,
) => {
	try {
		const response = await fetch(`${appConfig.apiUrl}/task/type/${type}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(body),
		})

		return await response.json()
	} catch (error) {
		console.error(error)
	}
}

export const fetchTaskProgress = async (id: number, token: string | null) => {
	try {
		const response = await fetch(`${appConfig.apiUrl}/task/${id}`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${token}`,
			},
		})

		return await response.json()
	} catch (error) {
		console.error('Error fetching task progress:', error)
		return null
	}
}
