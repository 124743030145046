import { createTheme } from '@mui/material/styles'

// Define a custom theme with a grey palette
export const theme = createTheme({
	palette: {
		grey: {
			50: '#f9f9f9',
			100: '#f0f0f0',
			200: '#d9d9d9',
			300: '#c0c0c0',
			400: '#a6a6a6',
			500: '#8c8c8c', // Default grey for buttons
			600: '#666666',
			700: '#4d4d4d',
			800: '#333333',
			900: '#1a1a1a',
		},
	},
})

export default theme
