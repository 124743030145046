import { createContext, useContext } from 'react'

import { DiagramContextProps } from '@/components/Diagram/types'

export const DiagramContext = createContext<DiagramContextProps>({
	diagramRef: null,
	isEditMode: false,
	node: null,
	exportPng: undefined,
	exportSvg: undefined,
	makeAutoLayout: undefined,
})

// Hook to use the context
export const useDiagramContext = () => {
	const context = useContext(DiagramContext)
	if (!context) {
		throw new Error('useDiagramContext must be used within a DiagramProvider')
	}
	return context
}

DiagramContext.displayName = 'DiagramContext'
